<template>
  <b-field v-if="!$_.isEmpty(order)" grouped>
    <b-input
      v-model="couponId"
      placeholder="Discount code? Enter it here..."
      :disabled="loadingCoupon || couponApplied"
      expanded
      @keyup.enter.exact.native="applyCoupon(couponId)"
    />
    <p class="control">
      <button
        class="button is-outlined"
        :disabled="($_.isEmpty(couponId) || loadingCoupon) && !couponApplied"
        :class="{ 'is-loading': loadingCoupon }"
        @click="couponApplied ? removeCoupon() : applyCoupon(couponId)"
      >
        {{ couponApplied ? "Remove" : "Apply" }}
      </button>
    </p>
  </b-field>
</template>

<script>
export default {
  name: "CouponField",
  props: {
    userId: {
      required: true,
      type: String
    },
    productId: {
      required: true,
      type: String
    },
    productTermId: {
      required: true,
      type: String
    },
    paymentMethodId: {
      required: true,
      type: String
    },
    siteId: {
      required: false,
      type: String,
      default: null
    },
    fqdn: {
      required: false,
      type: String,
      default: null
    },
    order: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      loadingCoupon: false,
      couponId: this.$_.get(this.order, "coupons[0].metadata.couponId", ""),
      lastUsedCoupon: this.$_.get(
        this.order,
        "coupons[0].metadata.couponId",
        ""
      )
    };
  },
  computed: {
    couponApplied() {
      return (
        !this.$_.isEmpty(this.lastUsedCoupon) &&
        !this.$_.isEmpty(this.order.coupons)
      );
    }
  },
  watch: {
    lastUsedCoupon(newVal) {
      this.$emit("input", newVal);
    }
  },
  methods: {
    removeCoupon() {
      this.couponId = "";
      this.applyCoupon().then(() => {
        this.lastUsedCoupon = "";
      });
    },
    applyCoupon(couponId = null) {
      this.loadingCoupon = true;
      return this.$store
        .dispatch("billing/calculateBasket", {
          userId: this.userId,
          siteId: this.siteId,
          fqdn: this.fqdn,
          productId: this.productId,
          productTermId: this.productTermId,
          paymentMethodId: this.paymentMethodId,
          couponId
        })
        .then(order => {
          this.$emit("order", order);
          if (couponId) {
            if (this.$_.isEmpty(order.coupons)) {
              this.$toast.open({
                message: `Invalid or expired coupon`,
                type: "is-danger"
              });
            } else {
              this.lastUsedCoupon = this.couponId;
              this.$toast.open({
                message: `Coupon applied`
              });
            }
          }
        })
        .catch(error => {
          this.$toast.open({
            message: error.message,
            type: "is-danger"
          });
        })
        .finally(() => {
          this.loadingCoupon = false;
        });
    }
  }
};
</script>
